@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;900&display=swap');

html {
    font-size: 14px;
}

body {
    overflow: hidden;
    margin: 0;
    font-family: 'Montserrat', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: 'Montserrat', sans-serif;
}

::-webkit-color-swatch {
  border: none;
  border-radius: .25rem;
}

.cellEditable{
    border: 1px solid #1e1e1e80 !important;
}