.ag-theme-quartz {
    // --ag-foreground-color: rgb(126, 46, 132);
    // --ag-background-color: rgb(249, 245, 227);
    // --ag-header-foreground-color: rgb(204, 245, 172);
    --ag-header-background-color: var(--chakra-colors-primary-700);
    --ag-header-foreground-color: var(--chakra-colors-white);
    --ag-header-column-resize-handle-color: var(--chakra-colors-primary-700);
    --ag-odd-row-background-color: var(--chakra-colors-primary-50);
    --ag-selected-row-background-color: var(--chakra-colors-primary-200);
    --ag-active-color: var(--chakra-colors-primary-100);
    --ag-font-size: 15px;
    --ag-font-family: 'Montserrat', sans-serif !important;
    --ag-checkbox-background-color: white;
    --ag-checkbox-checked-color: var(--chakra-colors-primary-500);
    --ag-checkbox-unchecked-color: var(--chakra-colors-primary-500);
    --ag-checkbox-indeterminate-color: var(--chakra-colors-primary-500);
    --ag-checkbox-label: var(--chakra-colors-white);
    --ag-border-color: transparent;
    --ag-header-border-color: transparent;
    --ag-row-border-color: transparent;
    --ag-row-height: 50px;
    --ag-control-panel-background-color: var(--chakra-colors-primary-50);
}

.ag-theme-quartz-dark {
    // --ag-foreground-color: rgb(126, 46, 132);
    --ag-background-color: var(--chakra-colors-secondary-800);
    // --ag-header-foreground-color: rgb(204, 245, 172);
    --ag-header-background-color: var(--chakra-colors-secondary-800);
    --ag-odd-row-background-color: var(--chakra-colors-secondary-900);
    --ag-selected-row-background-color: var(--chakra-colors-secondary-650);
    --ag-active-color: var(--chakra-colors-secondary-500);
    // --ag-header-column-resize-handle-color: rgb(126, 46, 132);
    --ag-font-size: 15px;
    --ag-font-family: 'Montserrat', sans-serif !important;
    --ag-checkbox-background-color: white;
    --ag-checkbox-checked-color: var(--chakra-colors-secondary-300);
    --ag-checkbox-unchecked-color: var(--chakra-colors-secondary-500);
    --ag-checkbox-indeterminate-color: var(--chakra-colors-secondary-500);
    --ag-border-color: transparent;
    --ag-header-border-color: transparent;
    --ag-row-border-color: transparent;
    --ag-row-height: 50px;
}

.ln-editable-cell {
    background-color: var(--ag-control-panel-background-color);
    cursor: pointer;
}

.ag-theme-quartz .ag-header-cell .ag-icon {
    color: var(--chakra-colors-white);
}

.ag-theme-quartz .ag-header-cell .ag-icon.ag-icon-filter {
    color: var(--chakra-colors-white);
}

.ag-theme-quartz .ag-header-cell .ag-icon.ag-icon-menu {
    color: var(--chakra-colors-white);
}

.ag-theme-quartz .ag-filter-toolpanel-group-title {
    color: #000;
}
